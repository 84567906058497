import { Noto_Sans } from 'next/font/google';
import { createTheme } from '@mui/material/styles';

const noto_sans = Noto_Sans({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
});

const theme = createTheme({
  palette: {
    primary: {
      light: '#dce99b',
      main: '#bbd430',
      dark: '#8f9c14',
    },
    secondary: {
      light: '#ffffff',
      main: '#efefef',
      dark: '#404041',
    },
  },
  typography: {
    fontFamily: noto_sans.style.fontFamily,
  },
});

export default theme;
