"use client";

import { SessionTableData } from "@/app/@types/surveysessionobj";
import React, { createContext, useState } from "react";

const PastSessionsContext = createContext<any>({});

/**
 * A React context provider component for the YEX post session surveys to be completed.
 *
 * @remarks
 * The context provider component is used to set and store the YEX surveys that require completion.
 *
 * @param param0
 * @returns - The React PastSessionsContext provider in JSX.
 */
export function PastSessionsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [pastSessions, setPastSessions] = useState<SessionTableData | null>(
    null
  );

  return (
    <PastSessionsContext.Provider value={{ pastSessions, setPastSessions }}>
      {children}
    </PastSessionsContext.Provider>
  );
}

export default PastSessionsContext;
