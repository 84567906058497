"use client";

import React, { createContext, useContext } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const QueryClientContext = createContext<QueryClient | undefined>(undefined);

/**
 * A React contxt provider to store the Tanstack React QueryClient.
 *
 * @remarks
 * This component stores and retreives the React Query QueryClient.
 * @param param0
 * @returns - The QueryClient provider in JSX.
 */
export function QueryClientContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const queryClient = new QueryClient();
  return (
    <QueryClientContext.Provider value={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </QueryClientContext.Provider>
  );
}

export default QueryClientContext;
